export const MODES_TYPE = {
	hover: 'HOVER',
	default: 'DEFAULT',
}

export const HOVER_SUFFIX = ':hover'

export const DEFAULT_DATA_MODE = '[data-mode=default]'

export const HOVER_DATA_MODE = '[data-mode=hover]'

export const getHoverCompId = (defaultCompId: string): string => `${defaultCompId}${HOVER_SUFFIX}`

export const getDefaultCompId = (hoverCompId: string): string =>
	hoverCompId ? hoverCompId.replace(HOVER_SUFFIX, '') : hoverCompId
