import { multi, withDependencies } from '@wix/thunderbolt-ioc'
import { isSSR } from '@wix/thunderbolt-commons'
import { ICurrentRouteInfo, IUrlHistoryManager, CandidateRouteInfo, IStaticRouteHandler } from './types'
import { IPageProvider, PageProviderSymbol } from 'feature-pages'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	CurrentRouteInfoSymbol,
	IAppDidLoadPageHandler,
	IAppWillLoadPageHandler,
	IPropsStore,
	IStructureAPI,
	LifeCycle,
	Props,
	StructureAPI,
} from '@wix/thunderbolt-symbols'
import { PageTransitionsCompletedSymbol, IPageTransitionsCompleted } from 'feature-page-transitions'
import { UrlHistoryManagerSymbol } from './symbols'

const onPageTransitionsCompleted = async (pageId: string, contextId: string, pageProvider: IPageProvider) => {
	const pageReflector = await pageProvider(contextId, pageId)
	const handlers = pageReflector.getAllImplementersOf<IAppDidLoadPageHandler>(LifeCycle.AppDidLoadPageHandler)
	const [pageTransitionsImp] = pageReflector.getAllImplementersOf<IPageTransitionsCompleted>(
		PageTransitionsCompletedSymbol
	)

	if (pageTransitionsImp) {
		pageTransitionsImp.onPageTransitionsCompleted(() => {
			handlers.map((handler) => handler.appDidLoadPage({ pageId, contextId }))
		})
	} else {
		handlers.map((handler) => handler.appDidLoadPage({ pageId, contextId }))
	}
}

export const StaticRouteHandler = withDependencies<IStaticRouteHandler>(
	[
		Props,
		StructureAPI,
		PageProviderSymbol,
		multi(LifeCycle.AppWillLoadPageHandler),
		CurrentRouteInfoSymbol,
		UrlHistoryManagerSymbol,
		BrowserWindowSymbol,
	],
	(
		props: IPropsStore,
		structureApi: IStructureAPI,
		pageProvider: IPageProvider,
		appWillLoadPageHandlers: Array<IAppWillLoadPageHandler>,
		currentRouteInfo: ICurrentRouteInfo,
		urlHistoryManager: IUrlHistoryManager,
		window: BrowserWindow
	) => {
		return {
			handleStaticRoute: async (routeInfo: CandidateRouteInfo) => {
				currentRouteInfo.updateCurrentRouteInfo(routeInfo)
				urlHistoryManager.pushUrlState(routeInfo.parsedUrl)

				const { contextId, pageId } = routeInfo

				// Create the page container
				pageProvider(contextId, pageId)

				await Promise.all(
					appWillLoadPageHandlers.map((handler) => handler.appWillLoadPage({ pageId, contextId }))
				)

				if (pageId !== contextId) {
					props.update({
						SITE_PAGES: {
							contextId,
						},
					})
				}
				structureApi.addPageAndRootToRenderedTree(pageId)

				await onPageTransitionsCompleted(pageId, contextId, pageProvider)

				return routeInfo
			},
			handleSamePageNavigation: (routeInfo, navigationParams) => {
				currentRouteInfo.updateCurrentRouteInfo(routeInfo)
				urlHistoryManager.pushUrlState(routeInfo.parsedUrl, navigationParams?.skipHistory)

				const shouldScrollToTop = !navigationParams?.disableScrollToTop
				if (!isSSR(window) && shouldScrollToTop) {
					window!.scrollTo({ top: 0 })
					const targetElement = window!.document.getElementById('SCROLL_TO_TOP')
					// eslint-disable-next-line no-unused-expressions
					targetElement?.focus()
				}
			},
		}
	}
)
